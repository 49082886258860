import React, { useState } from "react"
import Layout from "../../../components/layouts"
import Banner from "../../../components/features/Banner"
import SEO from "../../../components/common/SEO"
import ReactWOW from "react-wow"
import FeatureCards from "../../../components/features/FeatureCards"

export const query = graphql`
  query BehaviourObservations {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
        defaultImage: image
        twitterUsername
      }
    }
  }
`

export default ({ data }) => {
  const [toggler, setToggler] = useState(false)
  const lightboxProps = {
    toggler: toggler,
    setToggler: setToggler,
    sources: ["https://www.youtube.com/watch?v=xE2yXvWB038"],
  }

  const bannerProps = {
    title: "Behavior Observations",
    subtitle: `Unsafe environmental conditions and mechanical failures are easy enough to manage with systems like inspections and hazard assessments. But what about accidents that might have been affected by human behavior, such as fatigue, haste, distraction, poor habits or workers simply daring the odds? How do you identify those hazards and build a culture to root them out? Systematic observations can help bring these behaviors and attitudes to light. Behavior observations can be considered hazard assessments of a worker's mind and body.`,
    featureName: "behaviourObservations",
    rightButtonText: "Why Certificate Tracking Software?",
    rightButtonSrc: "https://www.youtube.com/watch?v=xE2yXvWB038",
    mockupImg: "/features/behavior-observations/feature-page-header-bbso.png",
  }

  const seo = {
    title: "Workhub | Free Behavior Observations Checklist Tool Safety BBSO Report",
    description:
      "Our Behavior-Based Safety tool lets you identify, submit, collect and trend data on risky or hazardous workplace behaviours so that you can improve employee safety and reduce indicidents/accidents.",
    image: "/features/behavior-observations/social-card.jpg",
    // pathname,
    // article
  }

  const featureIndex = [
    {
      title: "Behavior-Based Safety Observation",
      para: "Observe employees in their day-to-day activities to ensure that they are exhibiting behaviors and attitudes that contribute to a safe workplace.",
    },
    {
      title: "Identify ",
      para: "Identify behaviors that contribute to unwanted outcomes at your workplace and build checklists to sign-off on.",
    },
    {
      title: "Assign",
      para: "Assign observations by position and location.",
    },
    {
      title: "Designate",
      para: "Choose staff or supervisors that are qualified to assess other employees.",
    },
    {
      title: "Analyze the Results",
      para: "Filter your submissions by date and track the results over time. ",
    },

  ]

  return (
    <Layout {...lightboxProps}>
      <SEO {...seo} {...data.site} />
      <Banner {...bannerProps} {...lightboxProps} />

      <main className="feature-main">
        <section className="feature-section">
          <div className="container">
            <div className="row switch-children">
              <div className="col-lg-6 feature-img">
                <ReactWOW animation="fadeInLeft" delay="0.9s">
                  <img
                    className="feature-img-right"
                    src="/features/behavior-observations/bbso-comp-two.png"
                    alt="Behaviour Observation Computer View"
                  />
                </ReactWOW>
              </div>

              <div className="col-lg-6 feature-text">
                {/* <h2>
                Combatting Complacency 
                </h2> */}
                <p>
                  The concept of behavior based safety is not meant to stir up
                  debate on whether workers are "responsible" for unsafe acts,
                  or whether they are the root cause of injuries and quality
                  issues. Behavior based safety simply suggests undesirable
                  behaviors can contribute to unwanted outcomes, and that they
                  can be identified and corrected. Not every organization will
                  embrace behavior-based safety, but if you do, we have the tool
                  to make it work.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="feature-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 feature-text">
                {/* <h2>
                Keep your ducks in a row 
                </h2> */}

                <p style={{ marginBottom: "0px" }}>
                  The process starts with identifying behaviors that contribute
                  to unwanted outcomes at your workplace. Prior hazard
                  assessments and incident reports are a good place to look.
                  Root cause analysis might identify a few more. Not to worry if
                  your list is not that long though, Workhub has a list of
                  dozens of behavior templates from which to pick and choose.
                  Here are a few examples:
                </p>

                <ul className="checklist">
                  <li>Proper housekeeping (cleanliness, storage, access)</li>
                  <li>Correct tool or equipment for the task</li>
                  <li>PPE worn correctly</li>
                  <li>Mind on task (not distracted)</li>
                  <li>Not rushing</li>
                  <li>Not over-exerting</li>
                  <li>Stretches and takes micro-breaks</li>
                  <li>Not lifting, bending or twisting</li>
                  <li>Avoid repetitive motions</li>
                  <li>Comfortable (not awkward) position</li>
                </ul>

                <p>
                  {" "}
                  Keep in mind when creating your list that behaviors are{" "}
                  <strong>
                    <em>not</em>
                  </strong>{" "}
                  task-specific. So don't add a behavior for "Operates backhoe
                  safely". (Our Competency tool is what you'll want to use for
                  task-specific assessments.)
                </p>
              </div>

              <div className="col-lg-6 feature-img">
                <ReactWOW animation="fadeInLeft" delay="0.9s">
                  <img
                    className="feature-img-left"
                    src="/features/behavior-observations/bbso-comp-3.png"
                    alt="Behavior Observations Checklist View"
                  />
                </ReactWOW>
              </div>
            </div>
          </div>
        </section>

        <section className="feature-section">
          <div className="container">
            <div className="row switch-children">
              <div className="col-lg-6 feature-img">
                <ReactWOW animation="fadeInLeft" delay="0.9s">
                  <img
                    className="feature-img-right"
                    src="/features/behavior-observations/bbso-mobile.png"
                    alt="Behaviour Observation Mobile View"
                  />
                </ReactWOW>
              </div>

              <div className="col-lg-6 feature-text">
                {/* <h2>
                Set-up a Meeting 
                </h2> */}
                <p>
                  Next decide whether all behaviors need to be observed, or
                  whether some are <em>not always</em> applicable to each
                  observation. If you decide N/A is not a valid choice, then
                  "Safe" and "At-Risk" become the only available selections. You
                  can group the behaviors into sections, such as Body Mechanics,
                  Job Setup or Executing Work and sort the behaviors within each
                  grouping. Then assign it to the various roles in your company
                  (as optional or required) and set a frequency, such as 1
                  observation every month. The frequency requirement applies to
                  observers (observees are anonymous and therefore can't be
                  tracked).
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="feature-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 feature-text">
                {/* <h2>
                Keep your ducks in a row 
                </h2> */}

                <p>
                  You're basically ready to fire up Behavior Based Safety Observations (BBSOs). However before
                  enabling behavior observations, you might want to assign our
                  Behavior Observation course in Online Training, or maybe write
                  a Policy or Procedure on how they are to be done. But we
                  digress...
                </p>

                <p>
                  Workers with permissions to carry out Behavior Observations will be able
                  to navigate to the module and click "Start Behavior
                  Observation". It's usually a good idea to let the co-worker
                  know they're being observed and to provide feedback along the
                  way (or at least afterward). The observation form is optimized
                  for mobile phones. Observers identify the Location (for
                  reporting purposes) and simply click on the "Safe", "At Risk",
                  or "N/A" buttons and leave comments when appropriate.
                </p>

                <p>
                  Back in the Admin portal, the statistics start to pile up.
                  They'll give you insight into what behaviors are most at-risk
                  so that you can tailor your training, procedures, safety
                  meetings, bulletins and other tools to mitigate any hazards
                  and reinforce desired behaviors!
                </p>
              </div>

              <div className="col-lg-6 feature-img">
                <ReactWOW animation="fadeInLeft" delay="0.9s">
                  <img
                    className="feature-img-left"
                    src="/features/behavior-observations/bbso-tablet.png"
                    alt="Behaviour Observation Tablet View"
                  />
                </ReactWOW>
              </div>
            </div>
          </div>
        </section>

        {/* FEATURE OVERVIEW */}

        <section className="feature-overview-container">
          <h2>Overview</h2>

          <div className="feature-overview-grid">
            {featureIndex.map(item => (
              <FeatureCards title={item.title} para={item.para} />
            ))}
          </div>
        </section>


      </main>
    </Layout>
  )
}
